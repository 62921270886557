// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';

import React, { useEffect, useMemo, useRef } from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GLULayout } from '../../layouts/glu';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';
import Spinner from '../../assets/spinner.inline.svg';
import ScrollToTop from '../../components/glu/atoms/ScrollToTop/scrollToTop';
import { UnlockAccessForm } from '../../components/glu/organisms/unlock-access-form';
import { Head } from '../../components/templates/head';
import useFindCookie from '../../hooks/useCookie';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
	getOrganizationSeoSchema,
} from '../../utils/seo-schema';
import {
	getBreadcrumbs,
	getBreadcrumLocale,
	pageTypes,
} from '../../utils/url-helpers';
import { MainHeaderGluAds } from '../../components/molecules/main-header-glu-ads';
import ArticleNav from '../../components/glu/organisms/article-nav';
import { BreadCrumbs } from '../../components/atoms/breadcrumbs';
import { CardContainer } from '../../components/glu/organisms/course-container';

const GluAdPage = ({ data, location }) => {
	const { locale } = data.datoCmsGluAdPage;

	const onDemandRef = useRef();

	const headerCTAContent = {
		...data.datoCmsGluAdPage.hero,
		header: data.datoCmsGluAdPage.hero.header.value.document.children,
	};

	const { nodes: allGluCoursePages } = data.allDatoCmsGluCourseLink;
	const { nodes: id } = data.allCourses;
	const allCourseArray = id.flatMap((e) => e.id);

	const { nodes: articles } = data.allDatoCmsBlogPage;
	const { nodes: gluBanner } = data.allDatoCmsGluBanner;
	const { mainLogo } = data.allDatoCmsGluLogo.nodes[0];

	const metaContent = {
		noIndex: data.datoCmsGluAdPage.noIndex,
		noFollow: data.datoCmsGluAdPage.noFollow,
		metaInformation: data.datoCmsGluAdPage.seo,
		fallbackTitle: data.datoCmsGluAdPage.eLearningTitle,
		fallbackDescription: '',
		canonicalUrl: data.datoCmsGluAdPage?.canonicalUrl || location?.href,
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.gluAdPage,
			location,
		}),
		image: getImageSeoSchema(data?.headingImage?.url),
	};

	const coursesRef = useRef();
	const webcastRef = useRef(null);

	const scroll = (anchor) => {
		let top;
		switch (anchor) {
			case 'demand':
				top = onDemandRef;
				break;
			case 'courses':
				top = coursesRef;
				break;
			case 'webcast':
				top = webcastRef;
				break;
			default:
				top = null;
				break;
		}
		setTimeout(() => {
			window.scrollTo({
				top: top?.current?.offsetTop - 175,
				left: 0,
				behavior: 'smooth',
			});
		}, 100);
	};

	useEffect(() => {
		const isBrowser = typeof window !== 'undefined';
		if (isBrowser) {
			if (window.location.href.includes('#on-demand')) {
				scroll('demand');
			}
			if (window.location.href.includes('#courses')) {
				scroll('courses');
			}
			if (window.location.href.includes('#webcast')) {
				scroll('webcast');
			}
		}
	});

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsGluAdPage.internal.type,
		data.datoCmsGluAdPage
	);
	const onDemandCourses = data?.allDatoCmsGluCourse;

	const categoryIds = data.allCourseCategories.nodes;

	useMemo(() => {
		const allCourseCategories = [];
		const allCourseLinksCategories = [];

		[...categoryIds, ...allGluCoursePages].forEach((e) => {
			// eslint-disable-next-line compat/compat
			fetch(`/api/likes?item=${e.id}`)
				.then((res) => res.json())

				.then((courseData) => {
					const obj = {
						id: e.id,
						likes: courseData.likes,
					};
					if (obj.likes > 0) {
						if (
							allCourseArray.some((course) =>
								course.includes(e.id)
							)
						) {
							allCourseCategories.push(obj);
						} else {
							allCourseLinksCategories.push(obj);
						}
					}
				})
				// eslint-disable-next-line no-console
				.catch((err) => console.error(err));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { locale: urlLocale } = data.datoCmsGluAdPage;

	const { hasCookie, findingCookie, setFindingCookie, setHasCookie } =
		useFindCookie({
			name: 'elearningContent',
		});
	const [consentResponse, setConsentResponse] = React.useState(false);
	React.useEffect(() => {
		if (consentResponse || hasCookie) {
			setFindingCookie(false);
			setHasCookie(true);
		}
	}, [consentResponse, hasCookie, setFindingCookie, setHasCookie]);

	const removeHash = () => {
		if (window.location.hash) {
			window.history.pushState(
				'',
				document.title,
				window.location.pathname + window.location.search
			);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', removeHash);
		return () => {
			window.removeEventListener('scroll', removeHash);
		};
	}, []);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{locale === 'en' && articles.length > 0 && (
				<ArticleNav
					bannerData={gluBanner}
					articleData={articles}
					locale={locale}
				/>
			)}
			<div className="bg-brand-pale-500 w-100">
				<BreadCrumbs breadCrumbItems={breadCrumbItems} glu />
			</div>

			{findingCookie ? (
				<div className="flex flex-row justify-center py-10">
					<Spinner
						width="24"
						height="24"
						fill="currentColor"
						className="ml-2 animate-spin"
					/>
				</div>
			) : (
				<>
					{hasCookie || consentResponse ? (
						<>
							<MainHeaderGluAds
								urlLocale={urlLocale}
								{...headerCTAContent}
								glu
								logo={mainLogo}
								rating={data.datoCmsTrustpilotRating}
							/>
							<div ref={onDemandRef}>
								<CardContainer
									cardContainerContent={{
										id: 'course-container',
									}}
									cardVariant="courseList"
									data={onDemandCourses}
									associatedCategory={
										onDemandCourses?.nodes?.[0]
											?.associatedCategory
									}
									locale={locale}
								/>
							</div>
						</>
					) : (
						<div className="flex flex-row justify-center py-10  items-center w-full">
							<div className="w-full lg:max-w-xl lg:shadow-xl ">
								<UnlockAccessForm
									pageUrl={
										typeof window !== 'undefined'
											? window.location.href
											: ''
									}
									locale={locale}
									headerContent="Your expert e-learning content is waiting for you"
									subTextContent="Whether you're a client or not, access your e-learning sessions by sharing your details:"
									setConsentResponse={setConsentResponse}
									className="!rounded-none"
								/>
							</div>
						</div>
					)}
					{locale === 'en' && <ScrollToTop className="ml-10 mt-10" />}
				</>
			)}
		</GoogleReCaptchaProvider>
	);
};
const WrappedGluClientPage = (props) => (
	<GLULayout
		locale={props.data.datoCmsGluAdPage.locale}
		rating={props.data.datoCmsTrustpilotRating}
	>
		<GluAdPage data={props.data} location={props.location} />
	</GLULayout>
);

export default WrappedGluClientPage;

export const query = graphql`
	query GluAd($slug: String, $title: String, $locale: String) {
		datoCmsGluAdPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			id: originalId
			noIndex
			noFollow
			locale
			internal {
				type
			}
			seo {
				title
				description
				image {
					url
				}
				twitterCard
			}
			title
			slug
			hero {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				thirdLinkCopy
				thirdLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						imgixParams: { q: 100 }
						layout: CONSTRAINED
						height: 600
						width: 800
					)
				}
				showTrustPilot
			}
			seo {
				title
				description
				twitterCard
			}
		}
		datoCmsTrustpilotRating {
			id
			trustscore {
				id
				originalId
				stars
				trustscore
			}
		}

		filteredCourses: allDatoCmsGluCourse(
			limit: 3
			filter: {
				category: { title: { eq: $title } }
				locale: { eq: $locale }
			}
		) {
			nodes {
				id: originalId
				title
				intro
				slug
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 350
						height: 350
					)
				}
				category {
					title
					slug
				}
				timeToWatchInMinutes
			}
		}
		allDatoCmsGluCourse(
			sort: { order: ASC, fields: title }
			limit: 1
			filter: {
				associatedCategory: { eq: $title }
				isWebinarCatchUp: { ne: true }
				hideInOnDemandLibrary: { ne: true }
				locale: { eq: $locale }
			}
		) {
			totalCount
			nodes {
				associatedCategory
			}
		}

		allDatoCmsGluCourseLink(
			filter: {
				associatedCategory: { eq: $title }
				locale: { eq: $locale }
			}
		) {
			nodes {
				id: originalId
				link
				title
				intro
				associatedCategory
				showCpdLogo
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 412
						height: 162
					)
				}
			}
		}

		allCourseCategories: allDatoCmsGluCourse(
			sort: { order: ASC, fields: title }
			filter: {
				associatedCategory: { eq: $title }
				isWebinarCatchUp: { ne: true }
				hideInOnDemandLibrary: { ne: true }
				locale: { eq: $locale }
			}
		) {
			nodes {
				id: originalId
			}
		}
		allCourses: allDatoCmsGluCourse(filter: { locale: { eq: $locale } }) {
			nodes {
				id
			}
		}
		allDatoCmsGluLogo(filter: { locale: { eq: "en" } }) {
			nodes {
				title
				mainLogo {
					url
					alt
				}
			}
		}
		allDatoCmsGluBanner {
			nodes {
				link
				id
				title
				viewTitle
				enableFlashing
			}
		}
		allDatoCmsBlogPage(
			limit: 5
			sort: { order: DESC, fields: meta___createdAt }
		) {
			nodes {
				title
				slug
				categories {
					slug
				}
			}
		}
	}
`;
