// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Spinner from '../../assets/spinner.inline.svg';
import ModalWrapper from '../../components/glu/molecules/modal-wrapper';
import VideoPlayer from '../../components/glu/molecules/video-player';
import { AdviceModalForm } from '../../components/glu/organisms/advice-modal-form';
import ProspectLocked from '../../components/glu/organisms/prospect-locked';
import ProspectUnlocked from '../../components/glu/organisms/prospect-unlocked';
import { UnlockAccessForm } from '../../components/glu/organisms/unlock-access-form';
import { SuccessForm } from '../../components/organisms/success-form';
import { Head } from '../../components/templates/head';
import useModal from '../../hooks/modal';
import useFindCookie from '../../hooks/useCookie';
import { GLULayout } from '../../layouts/glu';
import { LOCALE_PATHS, buildLink } from '../../utils/locale';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
	getOrganizationSeoSchema,
} from '../../utils/seo-schema';
import { getBreadcrumLocale, pageTypes } from '../../utils/url-helpers';
import { createHeadingHighlight } from '../../utils/heading-highlight';
import * as headingStyles from '../../components/molecules/main-header/index.module.css';

const ProspectPage = ({ data, location }) => {
	const includedContentLength =
		data.datoCmsGluProspect.onDemandAndWebinars?.length || 0;

	const { locale } = data.datoCmsGluProspect;
	const currentLocale = LOCALE_PATHS[locale];

	const { hasCookie, findingCookie } = useFindCookie({
		name: 'elearningContent',
	});
	const ref = useRef();
	const { isModalOpen, setModalOpen } = useModal(ref);
	const [consentResponse, setConsentResponse] = useState(false);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	const [videoContent, setVideoContent] = useState(null);

	const metaContent = {
		noIndex: data.datoCmsGluProspect.noIndex,
		noFollow: data.datoCmsGluProspect.noFollow,
		metaInformation: data.datoCmsGluProspect.seoMeta,
		fallbackTitle: data.datoCmsGluProspect.eLearningTitle,
		fallbackDescription: '',
		canonicalUrl: data.datoCmsGluProspect?.canonicalUrl || location?.href,
	};

	const titleText =
		locale === 'en' &&
		createHeadingHighlight({
			headings:
				data.datoCmsReferABusiness.heroHeader[0].title.value.document
					.children[0].children,
			headingStyles,
		});

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.gluHome,
			location,
		}),
		image: getImageSeoSchema(data?.headingImage?.url),
	};

	useEffect(() => {
		if (consentResponse || hasCookie) {
			window.scrollTo(0, 0);
			// create a window object
			const url = typeof window !== 'undefined' ? window.location : null;
			// redirect to elearning page
			const newRedirect = '/elearning/';
			if (url) {
				url.replace(buildLink(locale, newRedirect));
			}
		}
	}, [consentResponse, locale, hasCookie]);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{findingCookie ? (
				<div className="flex flex-row justify-center py-10">
					<Spinner
						width="24"
						height="24"
						fill="currentColor"
						className="ml-2 animate-spin"
					/>
				</div>
			) : (
				<div>
					{consentResponse || hasCookie ? (
						<>
							<ProspectUnlocked
								data={data}
								setVideoContent={setVideoContent}
								setModalOpen={setModalOpen}
							/>
							<div>
								{isModalOpen && videoContent === 'Form' && (
									<ModalWrapper>
										<div
											className="relative m-auto mx-8 overflow-auto rounded-sm w-7/8 sm:w-3/4 lg:w-1/2 xl:w-1/3 md:mx-0"
											ref={ref}
										>
											{reference === '' ? (
												<AdviceModalForm
													setReference={setReference}
													setResponse={setResponse}
												/>
											) : (
												<SuccessForm
													reference={reference}
													response={response}
												/>
											)}
										</div>
									</ModalWrapper>
								)}
								{isModalOpen &&
									typeof videoContent === 'object' && (
										<ModalWrapper>
											<div
												className="relative w-7/12 m-auto "
												ref={ref}
											>
												{videoContent?.videoEmbed
													.providerUid && (
													<VideoPlayer
														videoEmbed={
															videoContent.videoEmbed
														}
														autoPlay
													/>
												)}
											</div>
										</ModalWrapper>
									)}
							</div>
						</>
					) : (
						<>
							<div className="flex flex-col">
								<ProspectLocked
									data={data}
									setModalOpen={setModalOpen}
									heroHeader={
										data.datoCmsReferABusiness.heroHeader[0]
									}
									slug={data.datoCmsReferABusiness.slug}
									titleText={titleText}
									headingStyles={headingStyles}
									locale={locale}
									rating={data.datoCmsTrustpilotRating}
								/>
							</div>
							<div>
								{isModalOpen && (
									<ModalWrapper>
										<div
											className="relative m-auto mx-8 overflow-auto rounded-sm w-7/8 sm:w-3/4 lg:w-1/2 xl:w-1/3 md:mx-0"
											ref={ref}
										>
											<button
												title="Close Peninsula Now Modal"
												type="button"
												onClick={() =>
													setModalOpen(false)
												}
												className="absolute flex top-[5px] text-2xl bg-tranparent font-bold right-[1px] opacity-100 border-2 border-brand-pale-200 h-9 w-9 items-center justify-center rounded-full text-white"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													x="0px"
													y="0px"
													width="20"
													height="20"
													viewBox="0 0 50 50"
													className="fill-current text-brand-pale-200 font-bold"
												>
													<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
												</svg>
											</button>
											<UnlockAccessForm
												setConsentResponse={
													setConsentResponse
												}
												cookieValue={
													includedContentLength
												}
												pageUrl={
													typeof window !==
													'undefined'
														? window.location.href
														: ''
												}
												setModalOpen={setModalOpen}
												locale={currentLocale}
											/>
										</div>
									</ModalWrapper>
								)}
							</div>
						</>
					)}
				</div>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedProspectPage = ({ data, location }) => (
	<GLULayout
		logoContent={data.allDatoCmsGluLogo.nodes[0].mainLogo}
		locale={data.datoCmsGluProspect.locale}
		rating={data.datoCmsTrustpilotRating}
	>
		<ProspectPage data={data} location={location} />
	</GLULayout>
);
export default WrappedProspectPage;

export const query = graphql`
	query prospectPage($locale: String!) {
		datoCmsGluProspect(locale: { eq: $locale }) {
			locale
			noIndex
			noFollow
			seoMeta {
				title
				description
				image {
					url
				}
				twitterCard
			}
			intro {
				doubleTextBlock {
					firstBlock {
						value
					}
					secondBlock {
						value
					}
				}
			}
			hero {
				vimeoLink {
					thumbnailUrl
					width
					height
					providerUid
					provider
					title
				}
				videoOverlayText
				trustpilotReview
				mainTitle {
					value
				}
				mainSubheading {
					value
				}
			}
			otherServices {
				title
				image {
					gatsbyImageData
					alt
				}
				ctaLink
				ctaText
				content {
					value
				}
				videoLinks {
					id
					title
					vimeoLink {
						thumbnailUrl
						width
						height
						providerUid
						provider
						title
					}
				}
			}
			otherServicesTitle {
				value
			}
			onDemandAndWebinars {
				id
				title
				slug
				intro
				category {
					slug
				}
				date(formatString: "MMMM Do YYYY, hh:mm")
				videoEmbed {
					thumbnailUrl
					width
					height
					providerUid
					provider
					title
				}
			}
			headingContent {
				value
			}
			headingImage {
				gatsbyImageData(width: 560)
				alt
				url
			}
			image {
				alt
				gatsbyImageData
			}
			messageContent {
				value
			}
			whatSIncludedTitle {
				value
			}
			whatSIncludedImage {
				gatsbyImageData
				alt
			}
			whatsIncludedContent {
				value
			}
			checklistImage {
				alt
				gatsbyImageData
			}
			checklist {
				value
			}
		}
		datoCmsTrustpilotRating(locale: { eq: $locale }) {
			id
			trustscore {
				id
				originalId
				stars
				trustscore
			}
		}
		allDatoCmsGluBanner(filter: { locale: { eq: $locale } }) {
			nodes {
				link
				id
				title
				viewTitle
				enableFlashing
			}
		}
		allDatoCmsBlogPage(
			limit: 5
			sort: { order: DESC, fields: meta___createdAt }
			filter: { locale: { eq: $locale } }
		) {
			nodes {
				title
				slug
				categories {
					slug
				}
			}
		}
		allDatoCmsGluCourse(
			filter: {
				locale: { eq: $locale }
				category: { slug: { ne: null } }
			}
			limit: 5
			sort: { order: DESC, fields: meta___createdAt }
		) {
			nodes {
				title
				slug
				category {
					slug
				}
				id
			}
		}
		allDatoCmsGluLogo(filter: { locale: { eq: "en" } }) {
			nodes {
				mainLogo {
					url
					alt
				}
			}
		}
		datoCmsReferABusiness(locale: { eq: $locale }) {
			slug
			heroHeader {
				id
				title {
					value
				}
			}
			referralCard {
				id
				heading
				locale
				referralCards {
					title
					content
				}
			}
		}
	}
`;
