import React, { useState, useEffect } from 'react';

import {
	renderMarkRule,
	renderNodeRule,
	StructuredText,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { graphql, useStaticQuery } from 'gatsby';
import { isHeading } from 'datocms-structured-text-utils';
import { Button } from '../../../atoms/button';
import WrapperImage from '../../../../images/glu-home-modal-wrapper/GLU-wrapper-image-eight.png';
import useIsMobile from '../../../../hooks/mobile';
import underline from '../../../../../static/images/underline.svg';

const WebinarModal = () => {
	const [isVisible, setIsVisible] = useState(false);
	const data = useStaticQuery(graphql`
		query MyGluPopUp {
			datoCmsGluPopUp {
				firstTitle
				fourthTitle
				ctaLink
				ctaText
				secondTitle {
					value
				}
				thirdTitle
			}
		}
	`);
	const {
		firstTitle,
		ctaLink,
		ctaText,
		secondTitle,
		thirdTitle,
		fourthTitle,
	} = data.datoCmsGluPopUp;
	// session storage open only once
	useEffect(() => {
		const hasSeenModal = sessionStorage.getItem('hasSeenModal');
		if (!hasSeenModal) {
			const timer = setTimeout(() => {
				setIsVisible(true);
			}, 15000);
			// Clean up the timer if the component unmounts
			return () => clearTimeout(timer);
		}
	}, []);
	const closeModal = () => {
		setIsVisible(false);
		sessionStorage.setItem('hasSeenModal', 'true');
	};

	const closeButton = () => {
		setIsVisible(false);
		sessionStorage.setItem('hasSeenModal', 'true');
	};
	const isMobile = useIsMobile();
	return (
		isVisible && (
			<div className="2xl:-mt-10 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
				<div
					className="bg-auto absolute glu2xl:h-full glumd:mt-10 text-white max-smd:text-xl glu2xl:p-20 p-1 w-full  max-w-m-screen my-auto mx-auto"
					style={{
						backgroundImage: `url(${WrapperImage})`,
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
					}}
				>
					<Button
						size={isMobile ? 'Tiny' : 'Normal'}
						className="absolute glulg:mb-10 glumd:mt-16 glumd:-mr-3 glulg:mt-16 glumdx:mt-16 bg-transparent  glu2xl:-top-[5.7%]  gluxs:-top-16 gluxs:mr-1 top-0 glu2xl:mt-[27%] glu2xl:right-[23%]   bg-none hover:bg-brand-red-500 right-0 gluxl:right-72  glulg:right-36 glulgx:right-72 glumd:right-12 gluxl:ml-10  glumdx:right-24  glulg:mt-10 glumdx:mt-10 glusm:mt-16 gluxs:mt-16 md:mt-10 glu2xl:right-80 text-white text-4xl rounded justify-center items-center border-none font-bold"
						onClick={closeModal}
					>
						&times;
					</Button>

					<div className="2xl:mt-72 md:mb-10 text-center justify-center max-w-m-screen mx-auto">
						<div className="max-smd:mt-5  max-smd:p-0 2xl:-mt-20 text-3xl max-smd:text-sm md:-mt-10  font-bold md:mb-2 flex items-center justify-center">
							<span className="glumd:mt-10 glu2xl:mt-6 glusm:text-xl glu2xl:text-2xl gluxs:text-sm max-smd:-mt-7 max-smd:text-sm text-2xl text-white px-2 py-1 mt-2 mr-2 rounded">
								{firstTitle}
							</span>
						</div>
						<h1 className="max-smd:text-sm max-smd:-mt-20  max-smd:mt-2 text-6xl glumd:mb-6 glumd:text-4xl max-smd:text-2xl max-smd:font-[13%] font-semibold mb-1">
							<span className="gluxs:text-sm  ">
								{/* <StructuredText data={secondTitle} /> */}
								{secondTitle && (
									<div
										className=""
										style={{
											backgroundImage: `url(${underline})`,
											backgroundPosition: 'bottom',
											backgroundRepeat: 'no-repeat',
											backgroundSize: '55% 8px',
											// paddingBottom: '0.01px',
										}}
									>
										<StructuredText
											data={secondTitle}
											customNodeRules={[
												renderNodeRule(
													isHeading,
													({
														node,
														children,
														key,
													}) => {
														const HeadingTag = `h${node.level}`;
														return (
															<HeadingTag
																className=" glu2xl:-mt-3 glu2xl:text-5xl gluxs:text-2xl gluxs:-mt-6 glusm:-mt-6 glumd:text-4xl glumd:mt-5  text-4xl glulg:text-5xl font-castledown-regular !leading-[3.5rem]"
																key={key}
															>
																{children}
															</HeadingTag>
														);
													}
												),
											]}
											customMarkRules={[
												renderMarkRule(
													'highlight',
													({ children, key }) => (
														<span
															className="font-bold text-brand-red-400"
															key={key}
														>
															{children}
														</span>
													)
												),
											]}
										/>
									</div>
								)}
							</span>
						</h1>

						<h3 className=" text-3xl gluxs:text-2xl glusm:text-xl gluxs:mb-6 ">
							{thirdTitle}
						</h3>
						<p className="bg-white  mx-auto py-2 px-5  max-smd:text-sm  text-lg  text-brand-red-500  glumd:py-2 font-bold  glu2xl:w-[55%] w-full glumd:w-[90%] glulg:w-[70%] glumdx:w-[70%] gluxl:w-[50%] glulgx:w-[55%] items-center jusify-center">
							{fourthTitle}
						</p>
						<br />
						<a href={ctaLink}>
							<Button
								onClick={closeButton}
								className="glumd:mb-5 glu2xl:top-10 top-0 hover:bg-brand-red-500 max-xs:text-[10%] max-smd:text-xs text-white md:-mb-12 [border-bottom: 1px solid red] px-4 rounded-lg hover:bg-brand-blue-400 transition-colors"
							>
								{ctaText} <br /> or review our Catch-Up
								Sessions!
							</Button>
						</a>
					</div>
				</div>
			</div>
		)
	);
};

export default WebinarModal;
