import React, { useState, useEffect, useRef } from 'react';
import loadScript from 'load-script2';
import { useFormik, FormikProvider, Field } from 'formik';
import clsx from 'clsx';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { graphql, useStaticQuery } from 'gatsby';
import {
	renderMarkRule,
	renderNodeRule,
	StructuredText,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isHeading } from 'datocms-structured-text-utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import PropTypes from 'prop-types';
import { Button } from '../../atoms/button';
import {
	validateEmail,
	validatePhoneNumber,
	validateString,
} from '../../../utils/marketo';
import { getCookie, sendTrackingData, sleep } from '../../../utils';
import { useMarketoFormSubmit } from '../../../hooks/marketo';
import { useFormValidation } from '../../../hooks/form-validation';
import { useMarketo } from '../../../contexts/marketo-context';
import { TextInput } from '../../atoms/text-input';
import { CallUs } from '../../atoms/call-us';
import { SidebarHeader } from '../../atoms/sidebar-header';
import useIsMobile from '../../../hooks/mobile';
import underline from '../../../../static/images/underline.svg';
import usePrivacyLink from '../../../hooks/usePrivacyLink';
import { PBSLink } from '../../atoms/link';

const ExitPopUp = ({ locale }) => {
	const [showPopUp, setShowPopUp] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [technicalError, setTechnicalError] = useState(false);
	const MktoForms2Instances = useMarketo();
	const formId = 7548;
	const isMobile = useIsMobile();
	const data = useStaticQuery(graphql`
		query MyExitPopUp {
			datoCmsExitPopUp {
				firstTitle
				fourthTitle
				fifthTitle
				popUpImage {
					alt
					gatsbyImageData
				}
				thirdTitle
				secondTitle {
					value
				}
			}
		}
	`);

	const {
		firstTitle,
		fourthTitle,
		secondTitle,
		thirdTitle,
		fifthTitle,
		popUpImage,
	} = data.datoCmsExitPopUp;
	const [referralCookie, setReferralCookie] = useState('');
	const [keywordCookie, setKeywordCookie] = useState('');
	const [mediumCookie, setMediumCookie] = useState('');
	const [campaignCookie, setCampaignCookie] = useState('');
	const [msclkidCookie, setMsclkidCookie] = useState('');
	const [msclkidLastCookie, setMsclkidLastCookie] = useState('');
	const [gclidCookie, setGclidCookie] = useState('');
	const [gclidLastCookie, setGclidLastCookie] = useState('');
	const [campaignLastCookie, setCampaignLastCookie] = useState('');
	const [contentLastCookie, setContentLastCookie] = useState('');
	const [mediumLastCookie, setMediumLastCookie] = useState('');
	const [sourceLastCookie, setSourceLastCookie] = useState('');
	const [termLastCookie, setTermLastCookie] = useState('');
	const [googleCookie, setGoogleCookie] = useState('');

	const popUpImageData = getImage(popUpImage);
	const { privacyLink } = usePrivacyLink(locale);

	useEffect(() => {
		if (isMobile) return;

		const handleMouseLeave = (e) => {
			if (
				(e.clientX <= 0 || e.clientY <= 0) &&
				!sessionStorage.getItem('exitPopUpShown')
			) {
				setShowPopUp(true);
				sessionStorage.setItem('exitPopUpShown', 'true');
			}
		};

		window.addEventListener('mouseout', handleMouseLeave);

		return () => {
			window.removeEventListener('mouseout', handleMouseLeave);
		};
	}, [isMobile]);

	const handleClose = () => {
		setShowPopUp(false);
		setShowSuccessMessage(false);
		setTechnicalError(false);
	};

	const initialValidations = {
		FirstName: { isValid: true },
		LastName: { isValid: true },
		Email: { isValid: true },
		Phone: { isValid: true },
	};

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const emailRef = useRef(null);
	const phoneRef = useRef(null);
	const { validations, validateForm } = useFormValidation(initialValidations);

	useEffect(() => {
		if (!validations?.FirstName?.isValid && firstNameRef?.current) {
			return firstNameRef.current.focus();
		}

		if (!validations?.LastName?.isValid && lastNameRef?.current) {
			return lastNameRef.current.focus();
		}

		if (!validations?.Email?.isValid && emailRef?.current) {
			return emailRef.current.focus();
		}

		if (!validations?.Phone?.isValid && phoneRef?.current) {
			return phoneRef.current.focus();
		}
	}, [validations]);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		PersonSource: 'Exit Pop Up',
		referalSource: referralCookie,
		keyword: keywordCookie,
		utm_medium: mediumCookie,
		utm_campaign: campaignCookie,
		MSCLKID__c: msclkidCookie,
		MSCLKID_last: msclkidLastCookie,
		GCLID__c: gclidCookie,
		gCLID_last: gclidLastCookie,
		utm_campaign_last__c: campaignLastCookie,
		utm_content_last__c: contentLastCookie,
		utm_medium_last__c: mediumLastCookie,
		utm_source_last__c: sourceLastCookie,
		utm_term_last__c: termLastCookie,
		Google_Cookie_ID__c: googleCookie,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
	});

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('exit_popup', {
			form_name: 'Exit Pop Up',
		});
	};

	const submitHandler = async (event) => {
		setLoading(true);
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);
		if (!isValid) {
			setLoading(false);
			return;
		}

		const payloadData = {
			...initialValues,
			FirstName: formEntries.FirstName,
			LastName: formEntries.LastName,
			Email: formEntries.Email,
			Phone: formEntries.Phone,
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
		};

		await sleep(500);
		useMarketoFormSubmit(payloadData, formId, MktoForms2Instances)
			.then(() => {
				formik.setStatus('submitted');
				setShowSuccessMessage(true);
				setTechnicalError(false);
				setLoading(false);
			})
			.catch(() => {
				formik.setStatus('error');
				setTechnicalError(true);
				setShowSuccessMessage(false);
			})
			.finally(() => {
				handleClickTracking();
				setLoading(false);
			});
	};

	useEffect(() => {
		if (isMobile) return;

		const loadForm = () =>
			loadScript(
				'https://app-lon04.marketo.com/js/forms2/js/forms2.min.js'
			)
				.then(() => {
					MktoForms2Instances.get(
						'https://app-lon04.marketo.com'
					).loadForm(
						'https://app-lon04.marketo.com',
						'023-IMK-845',
						formId
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}

		setReferralCookie(getCookie('utm_source'));
		setKeywordCookie(getCookie('utm_term'));
		setMediumCookie(getCookie('utm_medium'));
		setCampaignCookie(getCookie('utm_campaign'));
		setMsclkidCookie(getCookie('msclkid'));
		setMsclkidLastCookie(getCookie('msclkid_last'));
		setGclidCookie(getCookie('gclid'));
		setGclidLastCookie(getCookie('gclid_last'));
		setCampaignLastCookie(getCookie('utm_campaign_last'));
		setContentLastCookie(getCookie('utm_content_last'));
		setMediumLastCookie(getCookie('utm_medium_last'));
		setSourceLastCookie(getCookie('utm_source_last'));
		setTermLastCookie(getCookie('utm_term_last'));
		setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	}, [MktoForms2Instances, formId, isMobile]);

	const validateEmailPage = (value) => {
		let error;
		const emailAddressRegex =
			/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/i;

		if (!value) {
			error = false;
		} else if (!emailAddressRegex.test(value)) {
			error = false;
		} else {
			error = true;
		}
		return error;
	};
	const isEmailValid = validateEmailPage(emailRef?.current?.value);

	const btnText = 'Get your free download';

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<>
				{!isMobile && showPopUp && (
					<div className="fixed inset-0  bg-black bg-opacity-50 flex items-center justify-center z-50">
						<div className="flex flex-row glulgx:w-[70%] justify-center max-w-m-screen mx-auto glu2xl:w-[48%] ">
							<div className="flex-col flex items-center justify-center flex-1 w-1/2">
								<div className="p-[5%] bg-brand-blue-400 shadow-lg flex-1 w-full rounded-l-md ">
									<div className="h-24 flex  p-2">
										<h1 className=" font-bold glu2xl:text-4xl glulgx:text-3xl bg-transparent text-white ">
											{firstTitle}
										</h1>
									</div>

									<div className="flex flex-col  justify-center w-[30%]" />
									<div className=" glu2xl:mb-5  glulgx:mb-3 glu2xl:text-xl glulgx:text-xl glulgx:-mt-5 bg-transparent  text-white p-2">
										<span className="font-bold text-white">
											{thirdTitle}
										</span>
										<div
											className="text-brand-red-400 font-bold w-[70%] mr-8"
											style={{
												backgroundImage: `url(${underline})`,
												backgroundPosition: 'bottom',
												backgroundRepeat: 'no-repeat',
												backgroundSize: '92% 8px',
												paddingBottom: '5px',
											}}
										>
											<StructuredText
												data={secondTitle}
												customNodeRules={[
													renderNodeRule(
														isHeading,
														({
															node,
															children,
															key,
														}) => {
															const HeadingTag = `h${node.level}`;
															return (
																<HeadingTag
																	className=" glu2xl:-mt-3 glu2xl:text-5xl gluxs:text-2xl gluxs:-mt-6 glusm:-mt-6 glumd:text-4xl glumd:mt-5  text-4xl glulg:text-5xl font-castledown-regular !leading-[3.5rem]"
																	key={key}
																>
																	{children}
																</HeadingTag>
															);
														}
													),
												]}
												customMarkRules={[
													renderMarkRule(
														'highlight',
														({ children, key }) => (
															<span
																className=""
																key={key}
															>
																{children}
															</span>
														)
													),
												]}
											/>
										</div>
									</div>
									<p className=" glu2xl:text-md glulgx:text-md glulgx:-mt-5 bg-transparent p-2  font-thin text-white">
										{fourthTitle}
									</p>
									<br />
									<p className=" glu2xl:text-md glulgx:text-md glulgx:-mt-8 bg-transparent   p-2 text-white font-thin">
										{fifthTitle}
									</p>
								</div>

								<GatsbyImage
									image={popUpImageData}
									alt={popUpImage.alt || ''}
									key={popUpImage.url}
									className="border glu2xl:h-[50%] glulgx:h-60 bg-brand-blue-400 w-full object-cover -mt-16 rounded-bl-md"
								/>
							</div>

							<div className="p-[5%] bg-white w-[20%] flex-1 flex flex-col relative rounded-r-md">
								<Button
									type="button"
									variant="Unset"
									onClick={handleClose}
									className="absolute top-2  right-2 mr-2 mt-2 text-gray-500 bg-black hover:bg-brand-red-500 text-white rounded-lg hover:text-gray-700"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1.5}
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M6 18L18 6M6 6l12 12"
										/>
									</svg>
								</Button>

								{technicalError && (
									<div className="px-4 bg-brand-pale-300 text-brand-red-400">
										<SidebarHeader
											heading="Technical error occurred. Please try again."
											ariaLive="assertive"
										/>
										<CallUs className="px-4 pt-4 -py-14" />
										<Button
											type="button"
											onClick={() => {
												setTechnicalError(false);
												setLoading(false);
												formik.resetForm();
											}}
											className="mt-4 justify-center mb-32 ml-20 text-white items-center py-2 px-4 rounded"
										>
											Try Again
										</Button>
										<PBSLink
											to={privacyLink}
											variant="Link"
											target="_blank"
											rel="noreferrer"
											style={{
												textAlign: 'center',
												display: 'block',
											}}
										>
											Privacy policy
										</PBSLink>
									</div>
								)}
								{!technicalError && showSuccessMessage && (
									<>
										<div className="flex flex-col items-center justify-center mt-20 p-6">
											<h1 className="text-4xl text-center  font-castledown-regular text-blue-500 pb-10">
												Thank you!
											</h1>
											<h2 className="text-3xl text-center font-castledown-regular text-blue-500 pb-10">
												Your form has been submitted
												successfully.
											</h2>
											<h3 className="text-2xl text-center castledown-regular text-blue-500 pb-10">
												Your download is on its way to
												your inbox. Make sure to also
												check your junk mail.
											</h3>
										</div>
										<PBSLink
											to={privacyLink}
											variant="Link"
											target="_blank"
											rel="noreferrer"
											style={{
												textAlign: 'center',
												display: 'block',
											}}
										>
											Privacy policy
										</PBSLink>
									</>
								)}
								{!technicalError && !showSuccessMessage && (
									<FormikProvider value={formik}>
										<form
											onSubmit={submitHandler}
											data-formid={formId}
											data-forminstance="one"
										>
											<Field
												name="FirstName"
												validate={validateString}
											>
												{({ field }) => (
													<div>
														<label
															htmlFor="FirstName"
															className="text-brand-blue-400  font-centra-medium"
														>
															First Name&#42;
														</label>
														<TextInput
															id="FirstName"
															name="FirstName"
															aria-required="true"
															className="w-full mb-3  wrapper-small"
															ref={firstNameRef}
															showValidation={
																!validations
																	?.FirstName
																	?.isValid &&
																!firstNameRef
																	?.current
																	?.value
															}
															validationMessage={
																!validations
																	?.FirstName
																	?.isValid
																	? 'Please enter your first name'
																	: null
															}
															{...field}
															space={false}
															placeholder="Enter Your First Name..."
														/>
													</div>
												)}
											</Field>
											<Field
												name="LastName"
												validate={validateString}
											>
												{({ field }) => (
													<div>
														<label
															htmlFor="FullName"
															className="text-brand-blue-400 font-centra-medium"
														>
															Last Name&#42;
														</label>
														<TextInput
															id="LastName"
															name="LastName"
															aria-required="true"
															className="w-full mb-3 wrapper-small"
															ref={lastNameRef}
															showValidation={
																!validations
																	?.LastName
																	?.isValid &&
																!lastNameRef
																	?.current
																	?.value
															}
															validationMessage={
																!validations
																	?.LastName
																	?.isValid
																	? 'Please enter your last name'
																	: null
															}
															{...field}
															space={false}
															placeholder="Enter Your last Name..."
														/>
													</div>
												)}
											</Field>

											<Field
												name="Email"
												validate={validateEmail}
											>
												{({ field }) => (
													<div>
														<label
															htmlFor="Email"
															className="text-brand-blue-400 font-centra-medium"
														>
															Email&#42;
														</label>
														<TextInput
															id="Email"
															name="Email"
															type="email"
															aria-required="true"
															className="w-full mb-3 wrapper-small"
															ref={emailRef}
															showValidation={
																!isEmailValid &&
																!validations
																	?.Email
																	?.isValid
															}
															validationMessage={
																!validations
																	?.Email
																	?.isValid
																	? 'Please enter your email address'
																	: null
															}
															{...field}
															space={false}
															placeholder="xxxxxxx@gmail.com"
														/>
													</div>
												)}
											</Field>

											<Field
												name="Phone"
												validate={validatePhoneNumber}
											>
												{({ field }) => (
													<div>
														<label
															htmlFor="Phone"
															className="text-brand-blue-400 font-centra-medium"
														>
															Phone Number&#42;
														</label>
														<TextInput
															id="Phone"
															name="Phone"
															aria-required="true"
															className="w-full mb-3 wrapper-small"
															ref={phoneRef}
															showValidation={
																!validations
																	?.Phone
																	?.isValid &&
																!phoneRef
																	?.current
																	?.value
															}
															validationMessage={
																!validations
																	?.Phone
																	?.isValid
																	? 'Please enter your phone number'
																	: null
															}
															{...field}
															space={false}
															placeholder="Enter Your Phone Number..."
														/>
													</div>
												)}
											</Field>
											<div className="flex flex-col lg:items-center justify-center item-start lg:px-8 px-4 pt-3 pb-2 bg-white  lg:justify-between">
												<div className="wrapper-small ml-5">
													<Button
														type="submit"
														disabled={
															formik.isSubmitting
														}
														className={clsx(
															formik.isSubmitting
																? 'justify-center loading'
																: null
														)}
													>
														<span
															className={clsx(
																'text-sm',
																formik.isSubmitting
																	? 'invisible'
																	: null
															)}
														>
															{loading
																? 'please wait...'
																: btnText}
														</span>
														{formik.isSubmitting ? (
															<span
																className="sr-only"
																aria-live="assertive"
															>
																Submitting your
																details, please
																wait...
															</span>
														) : null}
													</Button>
												</div>
											</div>
											<PBSLink
												to={privacyLink}
												variant="Link"
												target="_blank"
												rel="noreferrer"
												style={{
													textAlign: 'center',
													display: 'block',
												}}
											>
												Privacy policy
											</PBSLink>
										</form>
									</FormikProvider>
								)}
							</div>
						</div>
					</div>
				)}
			</>
		</GoogleReCaptchaProvider>
	);
};

export default ExitPopUp;

ExitPopUp.defaultProps = {
	locale: 'en',
};

ExitPopUp.propTypes = {
	locale: PropTypes.string,
};
